import React from 'react';
import classes from './RetirementResults.module.scss'
import { connect } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import Popup from "reactjs-popup";
import SendResultByEmail from "../../components/SendResultByEmail/SendResultByEmail";
import { ReactComponent as Icon } from '../../assets/images/Layer_9.svg';
import { ReactComponent as Man } from '../../assets/images/man.svg';
import { ReactComponent as Woomen } from '../../assets/images/woomen.svg';
import { ReactComponent as Value } from '../../assets/images/Layer_5.svg';
import SimpleButton from "../../components/UI/SimpleButton/SimpleButton";

const RetirementResults = ( props ) => {

    const style={
        color: 'red',
        fontWeight: 'bold',
        fontSize: 24
    };

    function createRow(data={title: '', isYou: false, isSpouse: false, youVal: 0, spouseVal: 0}) {
        return (
            (data.isYou || data.isSpouse)
            &&
            <React.Fragment>
                <strong>{data.title}</strong>
                <p>
                    {
                        data.isYou &&
                        <React.Fragment>
                            You: <strong>${data.youVal} </strong>
                        </React.Fragment>
                    }
                    {
                        data.isSpouse && props.aboutYou.myFamily.spouseData.isSpouse &&
                        <React.Fragment>
                            | Spouse: <strong>${data.spouseVal} </strong>
                        </React.Fragment>
                    }
                </p>
            </React.Fragment>
        )
    }

    function createPropertiesCol(title, property) {
        return (
            property.length >0 &&
            <React.Fragment>
                <strong>{title}</strong>
                <p>
                    {
                        property.map((val, index)=> {
                            return (
                                <React.Fragment key={index}>
                                    {
                                        index === 1 &&
                                        <span> | </span>
                                    }                                                                                                             {index+1}: <strong>${val} </strong>
                                </React.Fragment>
                            )
                        })
                    }
                </p>
            </React.Fragment>
        )
    }

    function createInheritanceCol(title, inheritanceDataYou, inheritanceDataSpouse, type='') {
        const valYou = (type === 'year') ? inheritanceDataYou.inheritanceYear : inheritanceDataYou.total;
        const valSpouse = (type === 'year') ? inheritanceDataSpouse.inheritanceYear : inheritanceDataSpouse.total;
        return (
            <Col xs={12} sm={6}>
                <strong>{title}</strong>
                <p>
                    {
                        inheritanceDataYou.exist &&
                        <React.Fragment>
                                You: <strong>${valYou} </strong>
                            </React.Fragment>
                    }
                    {
                        inheritanceDataSpouse.exist &&
                        <React.Fragment>
                                | Spouse: <strong>${valSpouse} </strong>
                            </React.Fragment>
                    }
                </p>
            </Col>
        )
    }

    return (
        <section className={classes.RetirementResults}>
            <header>
                <div className={classes.HeaderBack}></div>
                <h1>YOUR RESULTS</h1>
            </header>

            <p className={classes.Congratulations}>Congratulations! You're on track to meet your retirement goals!</p>

            <div className={classes.HowYouDid}>
                <h2>HOW YOU DID</h2>
                <div className={classes.HowYouDid__result}>
                    <div><Icon style={{width:150, height: 150, paddingRight: 20}} /></div>
                    <div>
                        <div className={classes.ProgressBar}></div>
                        <div className={classes.ProgressBarValues}>
                            <span>$0</span>
                            <span>$1000</span>
                        </div>
                    </div>
                </div>
            </div>

            <section className={classes.IncomeEvaluation}>
                <header>
                    <h2>Your Retirement Income Evaluation</h2>
                </header>

                <div className={classes.Result}>
                    <div className={classes.ValueRetirement}>
                        <Row>
                            <Col xs={12} sm={12} md={6} >
                                <div className={classes.ValueRetirement__title}>
                                    <Value/><h3> Value at retirement </h3>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <ul>
                                    <li><span>RRSP</span><strong> $72,685 <span style={style}> ?</span></strong></li>
                                    <li><span>TFSA</span><strong> $72,685 <span style={style}> ?</span></strong></li>
                                    <li><span>Workplace savings assets</span><strong> $72,685 <span style={style}> ?</span></strong></li>
                                    <li><span>Non-registered assets</span><strong> $72,685 <span style={style}> ?</span></strong></li>
                                </ul>
                            </Col>
                        </Row>

                    </div>

                    <div className={classes.ValueRetirement__footer}>
                        <Row>
                            <Col mdOffset={6} md={6} lgOffset={6} lg={6} className='ValueRetirement__total'>
                                <span>For a total of</span>
                                <strong> $998,167,752</strong>
                            </Col>
                        </Row>
                    </div>

                    <div className={classes.ButtonsWrap}>
                        <SimpleButton title={'Back'}  type={'btn back'} />

                        <Popup modal trigger={<button className='btn' style={{marginBottom: 20, marginLeft: 20}}>Get pdf report</button>}>
                        {close => <SendResultByEmail close={close}/>}
                        </Popup>
                    </div>
                </div>

                <div className="retirement_info">
                    <div className="retirement_info__header">
                        <Row>
                            <Col xs={12} md={6} className={classes.PersonInfo}>
                                <div className={classes.IconWrap}>
                                    <Man/>
                                </div>
                                <div className={classes.Info}>
                                    <span>You</span>
                                    <strong>{props.aboutYou.iAm} | Age {props.aboutYou.iAmYearsOld} years</strong>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className={classes.PersonInfo}>
                                {
                                    props.aboutYou.myFamily.spouseData.isSpouse &&
                                    <React.Fragment>
                                        <div className={classes.IconWrap}>
                                            <Woomen/>
                                        </div>
                                        <div className={classes.Info}>
                                            <span>Spouse</span>
                                            <strong>{props.aboutYou.myFamily.spouseData.gender} | Age {props.aboutYou.myFamily.spouseData.yearsOld} years</strong>
                                        </div>
                                    </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </div>

                    <div className="retirement_info__section">
                        <Row>
                            <Col xs={12} md={3}>
                                <h3>About You</h3>
                            </Col>
                            <Col xs={12} md={9}>
                                {
                                    props.aboutYou.myFamily.childrenData.isChildren &&
                                    <div className="retirement_info__section__row">
                                        <div>
                                            <strong>Children</strong>
                                            <ul className='years'>
                                                {
                                                    props.aboutYou.myFamily.childrenData.children.years.map((val, index)=>{
                                                        return (
                                                            <li key={index}>Age {val} years</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }


                                {
                                    props.aboutYou.myFamily.dependantsData.isDependants &&
                                    <div className="retirement_info__section__row">
                                        <div>
                                            <strong>Dependants</strong>
                                            <ul className='years'>
                                                {
                                                    props.aboutYou.myFamily.dependantsData.dependants.years.map((val, index)=>{
                                                        return (
                                                            <li key={index}>Age {val} years</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }

                                <div className="retirement_info__section__row">
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <strong>Retirement age</strong>
                                            <p>You: <strong>{props.aboutYou.retireAge} years</strong></p>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <strong>Income needed in retirement</strong>
                                            <p><strong>${props.aboutYou.anticipatedIncome}</strong></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <strong>Number of years in retirement</strong>
                                            <p>You: <strong>{props.aboutYou.yearsIncome} years</strong></p>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <strong>Inflation assumption</strong>
                                            <p><strong>{props.aboutYou.inflation}%</strong></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            {
                                                props.aboutYou.rentOrOwn &&
                                                <React.Fragment>
                                                        <strong>Rent or own a home</strong>
                                                        <p><strong>{props.aboutYou.rentOrOwn}</strong></p>
                                                    </React.Fragment>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="retirement_info__section">
                        <Row>
                            <Col xs={12} md={3}>
                                <h3>Investor Profile</h3>
                            </Col>
                            <Col xs={12} md={9}>

                                <div className="retirement_info__section__row">
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <strong>Profile</strong>
                                            <p>
                                                You: <strong>{props.investorProfile.profileYou} </strong>
                                                {
                                                    props.aboutYou.myFamily.spouseData.isSpouse &&
                                                    <React.Fragment>
                                                            | Spouse: <strong>{props.investorProfile.profileSpouse}</strong>
                                                        </React.Fragment>
                                                }
                                            </p>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <strong>Guaranteed retirement income?</strong>
                                            <p>
                                                You: <strong>{props.investorProfile.guaranteedFeeYou} </strong>
                                                {
                                                    props.aboutYou.myFamily.spouseData.isSpouse &&
                                                    <React.Fragment>
                                                            | Spouse: <strong>{props.investorProfile.guaranteedFeeSpouse}</strong>
                                                        </React.Fragment>
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <strong>Withstand short-term-losses?</strong>
                                            <p>
                                                You: <strong>{props.investorProfile.shortTermLossesYou} </strong>
                                                {
                                                    props.aboutYou.myFamily.spouseData.isSpouse &&
                                                    <React.Fragment>
                                                            | Spouse: <strong>{props.investorProfile.shortTermLossesSpouse}</strong>
                                                        </React.Fragment>
                                                }
                                            </p>
                                        </Col>
                                        {
                                            (props.investorProfile.guaranteedFeeYou  === 'Yes' || props.investorProfile.guaranteedFeeSpouse === 'Yes') &&
                                            <Col xs={12} sm={6}>
                                                <strong>Percentage income guaranteed</strong>
                                                <p>
                                                    {
                                                        (props.investorProfile.guaranteedFeeYou === 'Yes') &&
                                                        <React.Fragment>
                                                               You: <strong>{props.investorProfile.guaranteedPercentageYou}% </strong>
                                                            </React.Fragment>
                                                    }
                                                    {
                                                        props.aboutYou.myFamily.spouseData.isSpouse && props.investorProfile.guaranteedFeeSpouse === 'Yes' &&
                                                        <React.Fragment>
                                                                | Spouse: <strong>{props.investorProfile.guaranteedPercentageSpouse}%</strong>
                                                            </React.Fragment>
                                                    }
                                                </p>
                                            </Col>
                                        }

                                    </Row>
                                    <Row>
                                        {
                                            (props.investorProfile.longTermReturnsYou || props.investorProfile.longTermReturnsSpouse) &&
                                            <Col xs={12} sm={6}>
                                                <strong>Tolerate period of volatility?</strong>
                                                <p>
                                                    You: <strong>{props.investorProfile.longTermReturnsYou} </strong>
                                                    {
                                                        props.aboutYou.myFamily.spouseData.isSpouse && props.investorProfile.longTermReturnsSpouse &&
                                                        <React.Fragment>
                                                                | Spouse: <strong>{props.investorProfile.longTermReturnsSpouse}</strong>
                                                            </React.Fragment>
                                                    }
                                                </p>
                                            </Col>
                                        }

                                        <Col xs={12} sm={6}>
                                            <strong>Rate of Return</strong>
                                            <p>
                                                You: <strong>{props.investorProfile.rateOfReturnYou}% </strong>
                                                {
                                                    props.aboutYou.myFamily.spouseData.isSpouse &&
                                                    <React.Fragment>
                                                            | Spouse: <strong>{props.investorProfile.rateOfReturnSpouse}%</strong>
                                                        </React.Fragment>
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                    {
                                        (props.investorProfile.longTermReturnsYou  === 'Yes' || props.investorProfile.longTermReturnsSpouse  === 'Yes') &&
                                        <Row>
                                            <Col xs={12}>
                                                <strong>Anticipated recovery period</strong>
                                                 <p>
                                                     {
                                                         (props.investorProfile.longTermReturnsYou === 'Yes') &&
                                                         <React.Fragment>
                                                                You: <strong>Up to {props.investorProfile.recoveryPeriodYou} year </strong>
                                                             </React.Fragment>
                                                     }{
                                                     (props.aboutYou.myFamily.spouseData.isSpouse && props.investorProfile.longTermReturnsSpouse === 'Yes') &&
                                                     <React.Fragment>
                                                                | Spouse: <strong>Up to {props.investorProfile.recoveryPeriodSpouse} year</strong>
                                                            </React.Fragment>
                                                 }
                                                </p>
                                            </Col>
                                        </Row>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="retirement_info__section">
                        <Row>
                            <Col xs={12} md={3}>
                                <h3>Savings and Investments</h3>
                            </Col>
                            <Col xs={12} md={9}>
                                <div className="retirement_info__section__row">
                                    {
                                        (props.savingsAndInvestments.you.rrsp.exist
                                            || props.savingsAndInvestments.spouse.rrsp.exist)
                                        &&
                                        <Row>
                                        <Col xs={12}><h4>RRSP</h4></Col>
                                        <Col xs={12} sm={6}>
                                            {
                                                createRow({
                                                              title: 'Total in RRSP',
                                                              isYou: props.savingsAndInvestments.you.rrsp.exist,
                                                              isSpouse: props.savingsAndInvestments.spouse.rrsp.exist,
                                                              youVal : props.savingsAndInvestments.you.rrsp.total,
                                                              spouseVal :  props.savingsAndInvestments.spouse.rrsp.total
                                                          })
                                            }
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            {
                                                createRow({
                                                              title: 'Monthly Contribution',
                                                              isYou: props.savingsAndInvestments.you.rrsp.exist,
                                                              isSpouse: props.savingsAndInvestments.spouse.rrsp.exist,
                                                              youVal : props.savingsAndInvestments.you.rrsp.contributeMonthly,
                                                              spouseVal :  props.savingsAndInvestments.spouse.rrsp.contributeMonthly
                                                          })
                                            }
                                        </Col>
                                    </Row>
                                    }

                                    {
                                        (props.savingsAndInvestments.you.workplaceSavings.exist
                                            || props.savingsAndInvestments.spouse.workplaceSavings.exist)
                                        &&
                                        <Row>
                                        <Col xs={12}><h4>Workplace savings</h4></Col>
                                        <Col xs={12} sm={6}>
                                            {
                                                createRow({
                                                              title: 'Total in Workplace savings',
                                                              isYou: props.savingsAndInvestments.you.workplaceSavings.exist,
                                                              isSpouse: props.savingsAndInvestments.spouse.workplaceSavings.exist,
                                                              youVal : props.savingsAndInvestments.you.workplaceSavings.total,
                                                              spouseVal :  props.savingsAndInvestments.spouse.workplaceSavings.total
                                                          })
                                            }
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            {
                                                createRow({
                                                              title: 'Monthly Contribution',
                                                              isYou: props.savingsAndInvestments.you.workplaceSavings.exist,
                                                              isSpouse: props.savingsAndInvestments.spouse.workplaceSavings.exist,
                                                              youVal : props.savingsAndInvestments.you.workplaceSavings.contributeMonthly,
                                                              spouseVal :  props.savingsAndInvestments.spouse.workplaceSavings.contributeMonthly
                                                          })
                                            }
                                        </Col>
                                    </Row>
                                    }

                                    {
                                        (props.savingsAndInvestments.you.lockedIn.exist
                                            || props.savingsAndInvestments.spouse.lockedIn.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}><h4>Locked-In</h4></Col>
                                            <Col xs={12}>
                                                 {
                                                     createRow({
                                                                   title: 'Total in Locked-In',
                                                                   isYou: props.savingsAndInvestments.you.lockedIn.exist,
                                                                   isSpouse: props.savingsAndInvestments.spouse.lockedIn.exist,
                                                                   youVal : props.savingsAndInvestments.you.lockedIn.total,
                                                                   spouseVal :  props.savingsAndInvestments.spouse.lockedIn.total
                                                               })
                                                 }
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        (props.savingsAndInvestments.you.tfsa.exist
                                            || props.savingsAndInvestments.spouse.tfsa.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}><h4>TFSA</h4></Col>
                                            <Col xs={12} sm={6}>
                                                {
                                                    createRow({
                                                                  title: 'Total in TFSA',
                                                                  isYou: props.savingsAndInvestments.you.tfsa.exist,
                                                                  isSpouse: props.savingsAndInvestments.spouse.tfsa.exist,
                                                                  youVal : props.savingsAndInvestments.you.tfsa.total,
                                                                  spouseVal :  props.savingsAndInvestments.spouse.tfsa.total
                                                              })
                                                }
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                {
                                                    createRow({
                                                                  title: 'Monthly Contribution',
                                                                  isYou: props.savingsAndInvestments.you.tfsa.exist,
                                                                  isSpouse: props.savingsAndInvestments.spouse.tfsa.exist,
                                                                  youVal : props.savingsAndInvestments.you.tfsa.contributeMonthly,
                                                                  spouseVal :  props.savingsAndInvestments.spouse.tfsa.contributeMonthly
                                                              })
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        (props.savingsAndInvestments.you.nonRegistered.exist
                                            || props.savingsAndInvestments.spouse.nonRegistered.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}><h4>Non-Registered</h4></Col>
                                            <Col xs={12} sm={6}>
                                                {
                                                    createRow({
                                                                  title: 'Total in Non-Registered',
                                                                  isYou: props.savingsAndInvestments.you.nonRegistered.exist,
                                                                  isSpouse: props.savingsAndInvestments.spouse.nonRegistered.exist,
                                                                  youVal : props.savingsAndInvestments.you.nonRegistered.total,
                                                                  spouseVal :  props.savingsAndInvestments.spouse.nonRegistered.total
                                                              })
                                                }
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                {
                                                    createRow({
                                                                  title: 'Monthly Contribution',
                                                                  isYou: props.savingsAndInvestments.you.nonRegistered.exist,
                                                                  isSpouse: props.savingsAndInvestments.spouse.nonRegistered.exist,
                                                                  youVal : props.savingsAndInvestments.you.nonRegistered.contributeMonthly,
                                                                  spouseVal :  props.savingsAndInvestments.spouse.nonRegistered.contributeMonthly
                                                              })
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    <Row>
                                        <Col xs={12} sm={6}>
                                        {
                                            createPropertiesCol('Equity in home', props.savingsAndInvestments.properties.home)
                                        }
                                        </Col>
                                        {
                                            props.savingsAndInvestments.properties.isRentalProperties &&
                                            <Col xs={12} sm={6}>
                                                {
                                                    createPropertiesCol('Equity in rental property', props.savingsAndInvestments.properties.rentalProperties)
                                                }
                                            </Col>
                                        }

                                    </Row>
                                    {
                                        props.savingsAndInvestments.properties.isCottage &&
                                        <Row>
                                            <Col xs={12}>
                                                {
                                                    createPropertiesCol('Equity in cottage', props.savingsAndInvestments.properties.cottages)
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        (props.savingsAndInvestments.you.inheritance.exist
                                            || props.savingsAndInvestments.spouse.inheritance.exist)
                                        &&
                                        <Row>
                                            {
                                                createInheritanceCol('Inheritance', props.savingsAndInvestments.you.inheritance, props.savingsAndInvestments.spouse.inheritance)
                                            }
                                            {
                                                createInheritanceCol('Year', props.savingsAndInvestments.you.inheritance, props.savingsAndInvestments.spouse.inheritance, 'year')
                                            }
                                        </Row>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="retirement_info__section">
                        <Row>
                            <Col xs={12} md={3}>
                                <h3>Source of Income</h3>
                            </Col>
                            <Col xs={12} md={9}>

                                <div className="retirement_info__section__row">
                                    {
                                        (props.sourceOfIncome.you.cpp.exist
                                            || props.sourceOfIncome.spouse.cpp.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}>
                                                <h4>CPP</h4>
                                                {
                                                    createRow({
                                                                  title: 'Monthly CPP',
                                                                  isYou: props.sourceOfIncome.you.cpp.exist,
                                                                  isSpouse: props.sourceOfIncome.spouse.cpp.exist,
                                                                  youVal : props.sourceOfIncome.you.cpp.val,
                                                                  spouseVal :  props.sourceOfIncome.spouse.cpp.val
                                                              })
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        (props.sourceOfIncome.you.qpp.exist
                                            || props.sourceOfIncome.spouse.qpp.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}>
                                                <h4>QPP</h4>
                                                {
                                                    createRow({
                                                                  title: 'Monthly QPP',
                                                                  isYou: props.sourceOfIncome.you.qpp.exist,
                                                                  isSpouse: props.sourceOfIncome.spouse.qpp.exist,
                                                                  youVal : props.sourceOfIncome.you.qpp.val,
                                                                  spouseVal :  props.sourceOfIncome.spouse.qpp.val
                                                              })
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        (props.sourceOfIncome.you.oasPension.exist
                                            || props.sourceOfIncome.spouse.oasPension.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}>
                                                <h4>OAS Pension</h4>
                                                {
                                                    createRow({
                                                                  title: 'Monthly OAS Pension',
                                                                  isYou: props.sourceOfIncome.you.oasPension.exist,
                                                                  isSpouse: props.sourceOfIncome.spouse.oasPension.exist,
                                                                  youVal : props.sourceOfIncome.you.oasPension.val,
                                                                  spouseVal :  props.sourceOfIncome.spouse.oasPension.val
                                                              })
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        (props.sourceOfIncome.you.employerPension.exist
                                            || props.sourceOfIncome.spouse.employerPension.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}>
                                                <h4>Employer Pension</h4>
                                                {
                                                    createRow({
                                                                  title: 'Monthly Employer Pension',
                                                                  isYou: props.sourceOfIncome.you.employerPension.exist,
                                                                  isSpouse: props.sourceOfIncome.spouse.employerPension.exist,
                                                                  youVal : props.sourceOfIncome.you.employerPension.val,
                                                                  spouseVal :  props.sourceOfIncome.spouse.employerPension.val
                                                              })
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        (props.sourceOfIncome.you.other.exist
                                            || props.sourceOfIncome.spouse.other.exist)
                                        &&
                                        <Row>
                                            <Col xs={12}>
                                                <h4>Other</h4>
                                                {
                                                    createRow({
                                                                  title: 'Monthly Other',
                                                                  isYou: props.sourceOfIncome.you.other.exist,
                                                                  isSpouse: props.sourceOfIncome.spouse.other.exist,
                                                                  youVal : props.sourceOfIncome.you.other.val,
                                                                  spouseVal :  props.sourceOfIncome.spouse.other.val
                                                              })
                                                }
                                            </Col>
                                        </Row>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>{/*.retirement_info*/}

            </section>

        </section>
    )
};

function mapStateToProps(state) {
    return {
        aboutYou: state.aboutYou,
        investorProfile: state.investorProfile,
        savingsAndInvestments: state.savingsAndInvestments,
        sourceOfIncome: state.sourceOfIncome,
    }
}

export default connect(mapStateToProps, null)(RetirementResults);