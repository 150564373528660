import React from 'react';

const Success = ( props ) => {
    return (
        <div className="notice notice-success">
            {props.message}
        </div>
    )
};

export default Success;