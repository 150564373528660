import React, { useEffect, useState } from 'react';
import { ReactComponent as Close } from "../../assets/images/CloseBtn.svg";
import classes from './SendResultByEmail.scss'
import Error from "../Error/Error";
import axios from 'axios';
import is from 'is_js';
import { connect } from "react-redux";
import Loading from "../UI/Loading/Loading";
import Success from "../Success/Success";

const SendResultByEmail = ( props ) => {

    const [customerData, setCustomerData] = useState( {
                                                          yourName: '',
                                                          yourEmail: '',
                                                      } );
    const [error, setError] = useState( {
                                        exist: false,
                                        errorMessage: 'The field required.'
                                        } );
    const [success, setSuccess] = useState( {
                                        exist: false,
                                        message: 'Your retirement results send successfully. Please, check your email.'
                                        } );

    const [loading, setLoading] = useState(false);

    const onChange = (event) => {
        setCustomerData({...customerData, [event.target.name]: event.target.value})
    };

    const hostname = 'https://cald.host';

    const onClick = () => {
        setSuccess({
            ...success,
            exist: false
                   });
        props.close();
    };

    const submitReport = async (e) => {
        e.preventDefault();

        const data = {
            aboutYou: props.aboutYou,
            investorProfile: props.investorProfile,
            savingsAndInvestments: props.savingsAndInvestments,
            sourceOfIncome: props.sourceOfIncome,
            email: customerData.yourEmail,
            name: customerData.yourName,
        };

        if(emailValidate()) {
            setLoading(true);
            await axios.post(hostname+'/wp-json/ndg-retirement-calc/v1/send-email', {data})
           .then(function (response) {
               setLoading(false);
               if (response.data.error) {
                   setError({
                                ...error,
                                exist: true,
                                errorMessage : response.data.errorMessage
                            });
                   setSuccess({
                                  ...success,
                                  exist: false,
                              })
               }else {
                   setError({
                                ...error,
                                exist: false,
                            });
                   setSuccess({
                                  ...success,
                                  exist: true,
                              })
               }
               console.log(response.data)
           })
           .catch(function (error) {
               console.log(error)
           })
        }

    };

    function emailValidate() {
        if(customerData.yourEmail === '') {
            setError({
                ...error,
                exist: true,
                errorMessage : 'The field required.'
                     });
            return false;
        }
        if(!is.email(customerData.yourEmail)){
            setError({
                         ...error,
                         exist: true,
                         errorMessage : 'The email is invalid.'
                     });
            return false;
        }

        return true;
    }

    return (
        <div className={classes.SendEmail}>
            <div>
                <form id='sendResultForm' onSubmit={submitReport}>
                    {
                        loading && <Loading/>
                    }
                    {
                        success.exist && <Success message={success.message}/>
                    }
                    <div>
                        <label htmlFor = "yourName">Your Name</label>
                        <input
                            id='yourName'
                            name='yourName'
                            type = "text"
                            value={customerData.yourName}
                            onChange={onChange}
                        />
                    </div>
                    <div style={{position: 'relative'}}>
                        {
                            error.exist &&
                                <Error message={error.errorMessage}/>
                        }
                        <label htmlFor = "yourEmail">Your Email<span style={{color: 'red'}}>*</span></label>
                        <input
                            id='yourEmail'
                            name='yourEmail'
                            type = "email"
                            value={customerData.yourEmail}
                            onChange={onChange}
                            required={true}
                        />
                    </div>
                    <div className='submitWrap'>
                        <input
                            type = "submit"
                            value = "Submit"
                            className='btn'
                        />
                    </div>
                </form>
            </div>
            <div className="closeBtn">
                <Close onClick={onClick}/>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        aboutYou: state.aboutYou,
        investorProfile: state.investorProfile,
        savingsAndInvestments: state.savingsAndInvestments,
        sourceOfIncome: state.sourceOfIncome,
    }
}

export default connect(mapStateToProps, null)(SendResultByEmail);